// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-business-hosting-js": () => import("./../../../src/pages/business-hosting.js" /* webpackChunkName: "component---src-pages-business-hosting-js" */),
  "component---src-pages-codeguard-js": () => import("./../../../src/pages/codeguard.js" /* webpackChunkName: "component---src-pages-codeguard-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-domains-js": () => import("./../../../src/pages/domains.js" /* webpackChunkName: "component---src-pages-domains-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketgoo-js": () => import("./../../../src/pages/marketgoo.js" /* webpackChunkName: "component---src-pages-marketgoo-js" */),
  "component---src-pages-ssl-dv-js": () => import("./../../../src/pages/SSL/DV.js" /* webpackChunkName: "component---src-pages-ssl-dv-js" */),
  "component---src-pages-ssl-ev-js": () => import("./../../../src/pages/SSL/EV.js" /* webpackChunkName: "component---src-pages-ssl-ev-js" */),
  "component---src-pages-ssl-js": () => import("./../../../src/pages/ssl.js" /* webpackChunkName: "component---src-pages-ssl-js" */),
  "component---src-pages-ssl-ov-js": () => import("./../../../src/pages/SSL/OV.js" /* webpackChunkName: "component---src-pages-ssl-ov-js" */),
  "component---src-pages-ssl-wildcard-js": () => import("./../../../src/pages/SSL/Wildcard.js" /* webpackChunkName: "component---src-pages-ssl-wildcard-js" */),
  "component---src-pages-student-hosting-js": () => import("./../../../src/pages/student-hosting.js" /* webpackChunkName: "component---src-pages-student-hosting-js" */),
  "component---src-pages-vpn-js": () => import("./../../../src/pages/vpn.js" /* webpackChunkName: "component---src-pages-vpn-js" */),
  "component---src-pages-vps-hosting-js": () => import("./../../../src/pages/vps-hosting.js" /* webpackChunkName: "component---src-pages-vps-hosting-js" */),
  "component---src-pages-web-hosting-js": () => import("./../../../src/pages/web-hosting.js" /* webpackChunkName: "component---src-pages-web-hosting-js" */),
  "component---src-pages-wordpress-hosting-js": () => import("./../../../src/pages/wordpress-hosting.js" /* webpackChunkName: "component---src-pages-wordpress-hosting-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */)
}

